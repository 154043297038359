<template>
  <div
    ref="dragWrapper"
    class="org-tree-drag-wrapper"
    @mousedown="mousedownView"
    @contextmenu="handleDocumentContextmenu"
  >
    <div class="org-tree-wrapper" :style="orgTreeStyle">
      <v-org-tree
        v-if="data"
        :data="data"
        :node-render="nodeRender"
        :expand-all="true"
        @on-node-click="handleNodeClick"
        collapsable
      ></v-org-tree>
    </div>
  </div>
</template>

<script>
import { on, off } from '@/utils/global'
import VOrgTree from 'v-org-tree'
import 'v-org-tree/dist/v-org-tree.css'

export default {
  name: 'OrgView',
  components: {
    VOrgTree
  },
  props: {
    zoomHandled: {
      type: Number,
      default: 1
    },
    data: Object
  },
  data () {
    return {
      orgTreeOffsetLeft: 0,
      orgTreeOffsetTop: 0,
      initPageX: 0,
      initPageY: 0,
      oldMarginLeft: 0,
      oldMarginTop: 0,
      canMove: false,
      menuDeptList : [
        // {
        //   key: 'create',
        //   label: this.$t('base.dept.createSon')
        // },
        // {
        //   key: 'edit',
        //   label: this.$t('base.dept.edit')
        // },
        // {
        //   key: 'delete',
        //   label: this.$t('base.dept.delete')
        // },
        {
          key: 'detail',
          label: this.$t('base.dept.details')
        }
      ],
      menuCompanyList : [
        // {
        //   key: 'create',
        //   label: this.$t('base.dept.create')
        // },
        // {
        //   key: 'edit',
        //   label: this.$t('base.company.edit')
        // },
        // {
        //   key: 'delete',
        //   label: this.$t('base.company.delete')
        // },
        {
          key: 'detail',
          label: this.$t('base.company.details')
        }
      ]
    }
  },
  computed: {
    orgTreeStyle () {
      return {
        transform: `translate(-50%, -50%) scale(${this.zoomHandled}, ${
          this.zoomHandled
        })`,
        marginLeft: `${this.orgTreeOffsetLeft}px`,
        marginTop: `${this.orgTreeOffsetTop}px`
      }
    }
  },
  methods: {
    handleNodeClick (e, data, expand) {
      expand()
    },
    nodeRender (h, data) {
      let menuList = null;
      if(data.parentId == -999){
        menuList = this.menuCompanyList;
      }else{
        menuList = this.menuDeptList;
      }
      return (
        <div
          class={[
            'custom-org-node',
            data.children && data.children.length ? 'has-children-label' : ''
          ]}
          on-mousedown={event => event.stopPropagation()}
        >
          {data.label}
          <el-tooltip content={menuList[0].label} placeman="bottom">
            <el-button type="text" style="margin-left: 6px;" onclick={this.handleContextMenuClick.bind(this, data, 'detail')}>
                <i class="el-icon-menu"></i>
            </el-button>
          </el-tooltip>
        </div>
      )
    },
    mousedownView (event) {
      this.canMove = true
      this.initPageX = event.pageX
      this.initPageY = event.pageY
      this.oldMarginLeft = this.orgTreeOffsetLeft
      this.oldMarginTop = this.orgTreeOffsetTop
      on(document, 'mousemove', this.mousemoveView)
      on(document, 'mouseup', this.mouseupView)
    },
    mousemoveView (event) {
      if (!this.canMove) return
      const { pageX, pageY } = event
      this.orgTreeOffsetLeft = this.oldMarginLeft + pageX - this.initPageX
      this.orgTreeOffsetTop = this.oldMarginTop + pageY - this.initPageY
    },
    mouseupView () {
      this.canMove = false
      off(document, 'mousemove', this.mousemoveView)
      off(document, 'mouseup', this.mouseupView)
    },
    handleDocumentContextmenu () {
      this.canMove = false
    },
    // 点击右键菜单选项，触发父级on-menu-click事件
    handleContextMenuClick (data, key) {
      this.$emit('on-menu-click', { data, key })
    }
  },
  mounted () {
    on(document, 'contextmenu', this.handleDocumentContextmenu)
  },
  beforeDestroy () {
    off(document, 'contextmenu', this.handleDocumentContextmenu)
  }
}
</script>

<style>
</style>
