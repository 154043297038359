var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zoom-wrapper" },
    [
      _c("el-button", {
        attrs: { icon: "el-icon-zoom-out", circle: "", size: "mini" },
        on: {
          click: function ($event) {
            return _vm.scale("down")
          },
        },
      }),
      _c("span", { staticClass: "zoom-number" }, [
        _vm._v(_vm._s(_vm.value) + "%"),
      ]),
      _c("el-button", {
        attrs: { icon: "el-icon-zoom-in", circle: "", size: "mini" },
        on: {
          click: function ($event) {
            return _vm.scale("up")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }