var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container-empty" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { style: { width: "calc(25% - 5px)" } },
            [
              _c(
                "el-row",
                [
                  _c("el-card", { staticClass: "orgCard" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardHeader",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("tenant-select", {
                                  attrs: { model: 2 },
                                  on: { change: _vm.getOrganization },
                                  model: {
                                    value: _vm.listQuery.tenantId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "tenantId", $$v)
                                    },
                                    expression: "listQuery.tenantId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-flow": "row-reverse",
                                  "margin-top": "4px",
                                },
                                attrs: { span: 8 },
                              },
                              [
                                _vm.presentCompany != 0
                                  ? [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            display: "flex",
                                          },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: _vm.radioTab,
                                            callback: function ($$v) {
                                              _vm.radioTab = $$v
                                            },
                                            expression: "radioTab",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  _vm.$t("commons.overView"),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-view",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  _vm.$t("commons.details"),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "2" } },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-s-operation",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "tree",
                          attrs: {
                            data: _vm.data,
                            "node-key": "id",
                            "auto-expand-parent": true,
                            "highlight-current": true,
                            "allow-drop": _vm.allowDrop,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ node, data }) {
                                return _c(
                                  "span",
                                  {
                                    staticClass: "custom-tree-node",
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.mouseoverNode(node)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.mouseoutNode(node)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickNode(node, data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(node.label) +
                                            "\n                  "
                                        ),
                                        data.parentId != _vm.rootPId
                                          ? [
                                              _vm._v(
                                                "\n                    (" +
                                                  _vm._s(data.empCount) +
                                                  ")\n                  "
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { style: { width: "calc(75% - 5px)", marginLeft: "10px" } },
            [
              _vm.radioTab == 1
                ? [
                    _c(
                      "el-card",
                      {
                        staticStyle: {
                          height: "100%",
                          width: "calc(75% - 5px)",
                          "overflow-x": "scroll",
                          position: "absolute",
                        },
                      },
                      [
                        _c("div", { staticClass: "department-outer" }, [
                          _c(
                            "div",
                            { staticClass: "tip-box" },
                            [
                              _c("title-icon"),
                              _c(
                                "b",
                                { staticStyle: { "margin-right": "20px" } },
                                [_vm._v(_vm._s(_vm.organizationName))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "zoom-box" },
                            [
                              _c("zoom-controller", {
                                attrs: { min: 20, max: 200 },
                                model: {
                                  value: _vm.zoom,
                                  callback: function ($$v) {
                                    _vm.zoom = $$v
                                  },
                                  expression: "zoom",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "view-box" },
                            [
                              _vm.companyData
                                ? _c("org-view", {
                                    attrs: {
                                      data: _vm.companyData,
                                      "zoom-handled": _vm.zoomHandled,
                                    },
                                    on: {
                                      "on-menu-click": _vm.handleMenuClick,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "el-row",
                      [
                        _c("el-card", { staticClass: "orgCard" }, [
                          _c(
                            "div",
                            {
                              staticClass: "cardHeader",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("title-icon"),
                              _c("span", [
                                _vm._v(_vm._s(_vm.publicTButton.label)),
                              ]),
                              _c("el-tag", { staticClass: "publicTag" }, [
                                _vm._v(_vm._s(_vm.publicTButton.title)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("employee-details", {
                                attrs: {
                                  listQuery: _vm.employee.listQuery,
                                  currentNode: _vm.currentNode,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.company.addDialogVisible,
            "before-close": _vm.cancelCompanySave,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.company, "addDialogVisible", $event)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-icon"), _vm._v(_vm._s(_vm.company.title) + " ")],
            1
          ),
          _c(
            "el-form",
            {
              ref: "companyForm",
              attrs: {
                "label-position": "top",
                model: _vm.company.form,
                rules: _vm.company.formRules,
              },
            },
            [
              _c("el-input", {
                staticClass: "hidden",
                attrs: { type: "hidden" },
                model: {
                  value: _vm.company.form.companyId,
                  callback: function ($$v) {
                    _vm.$set(_vm.company.form, "companyId", $$v)
                  },
                  expression: "company.form.companyId",
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.company.companyName"),
                    prop: "companyName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "25",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setCompanyCode()
                      },
                    },
                    model: {
                      value: _vm.company.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.company.form, "companyName", $$v)
                      },
                      expression: "company.form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.company.companyCode"),
                    prop: "companyCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.company.form.companyCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.company.form, "companyCode", $$v)
                      },
                      expression: "company.form.companyCode",
                    },
                  }),
                ],
                1
              ),
              _vm.company.directorVisible == true
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("base.device.principal"),
                          prop: "companyDirectorId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "regionSelect",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: _vm.$t("commons.selectPlease"),
                            },
                            model: {
                              value: _vm.company.form.companyDirectorId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.company.form,
                                  "companyDirectorId",
                                  $$v
                                )
                              },
                              expression: "company.form.companyDirectorId",
                            },
                          },
                          _vm._l(_vm.companyEmp, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.userName,
                                value: item.userId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.company.label"),
                    prop: "tagList",
                  },
                },
                [
                  _c("tag-bind", {
                    attrs: {
                      tagValueList: _vm.company.form.tagList,
                      tagType: "company",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelCompanySave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.company.createLoading,
                      expression: "company.createLoading",
                      modifiers: { fullscreen: true, lock: true },
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCompanySave },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dept.addDialogVisible,
            top: "6vh",
            "before-close": _vm.cancelDeptSave,
            width: "32%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dept, "addDialogVisible", $event)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-icon"), _vm._v(_vm._s(_vm.dept.title) + " ")],
            1
          ),
          _c(
            "el-form",
            {
              ref: "deptForm",
              attrs: {
                "label-position": "top",
                model: _vm.dept.form,
                rules: _vm.dept.formRules,
              },
            },
            [
              _c("el-input", {
                staticClass: "hidden",
                attrs: { type: "hidden" },
                model: {
                  value: _vm.dept.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.dept.form, "deptId", $$v)
                  },
                  expression: "dept.form.deptId",
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.dept.deptName"),
                    prop: "deptName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "33",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setDeptCode()
                      },
                    },
                    model: {
                      value: _vm.dept.form.deptName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "deptName", $$v)
                      },
                      expression: "dept.form.deptName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.dept.deptCode"),
                    prop: "deptCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.dept.form.deptCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "deptCode", $$v)
                      },
                      expression: "dept.form.deptCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.company.name"),
                    prop: "companyId",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "hidden",
                    attrs: { type: "hidden" },
                    model: {
                      value: _vm.dept.form.companyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "companyId", $$v)
                      },
                      expression: "dept.form.companyId",
                    },
                  }),
                  _c("el-input", {
                    attrs: { readonly: "readonly" },
                    model: {
                      value: _vm.dept.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "companyName", $$v)
                      },
                      expression: "dept.form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.dept.supDept"),
                    prop: "parentId",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "hidden",
                    attrs: { type: "hidden" },
                    model: {
                      value: _vm.dept.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "parentId", $$v)
                      },
                      expression: "dept.form.parentId",
                    },
                  }),
                  _c("el-input", {
                    attrs: { readonly: "readonly" },
                    model: {
                      value: _vm.dept.form.parentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dept.form, "parentName", $$v)
                      },
                      expression: "dept.form.parentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("base.device.principal"),
                    prop: "deptHeadId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "regionSelect",
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.$t("commons.selectPlease"),
                      },
                      model: {
                        value: _vm.dept.form.deptHeadId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dept.form, "deptHeadId", $$v)
                        },
                        expression: "dept.form.deptHeadId",
                      },
                    },
                    _vm._l(_vm.companyEmp, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.userName, value: item.userId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.dept.deptDisable == true
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("base.dept.dutyName"),
                          prop: "dutyList",
                        },
                      },
                      [
                        _vm._l(
                          _vm.dept.form.dutyList,
                          function (dutyVO, index) {
                            return [
                              _c(
                                "div",
                                {
                                  key: dutyVO.dutyId,
                                  staticStyle: { "margin-bottom": "5px" },
                                  on: {
                                    mouseenter: function ($event) {
                                      dutyVO.show = true
                                    },
                                    mouseleave: function ($event) {
                                      dutyVO.show = false
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "hidden",
                                    attrs: { type: "hidden" },
                                    model: {
                                      value: dutyVO.dutyId,
                                      callback: function ($$v) {
                                        _vm.$set(dutyVO, "dutyId", $$v)
                                      },
                                      expression: "dutyVO.dutyId",
                                    },
                                  }),
                                  _c("el-input", {
                                    staticClass: "dutyClass",
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: {
                                      maxlength: "33",
                                      "show-word-limit": true,
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "commons.pleaseInput"
                                      ),
                                    },
                                    model: {
                                      value: dutyVO.dutyName,
                                      callback: function ($$v) {
                                        _vm.$set(dutyVO, "dutyName", $$v)
                                      },
                                      expression: "dutyVO.dutyName",
                                    },
                                  }),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "commons.selectPlease"
                                        ),
                                      },
                                      on: {
                                        clear: function ($event) {
                                          return _vm.clearEmpty(dutyVO)
                                        },
                                      },
                                      model: {
                                        value: dutyVO.dutyPerson,
                                        callback: function ($$v) {
                                          _vm.$set(dutyVO, "dutyPerson", $$v)
                                        },
                                        expression: "dutyVO.dutyPerson",
                                      },
                                    },
                                    _vm._l(_vm.deptEmp, function (item) {
                                      return _c("el-option", {
                                        key: item.userId,
                                        attrs: {
                                          label: item.userName,
                                          value: item.userId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-button", {
                                    attrs: {
                                      type: "success",
                                      icon: "el-icon-circle-plus-outline",
                                      size: "mini",
                                      round: "",
                                    },
                                    on: { click: _vm.addDutyNode },
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      type: "warning",
                                      icon: "el-icon-delete",
                                      size: "mini",
                                      round: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDutyNode(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDeptSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.dept.createLoading,
                      expression: "dept.createLoading",
                      modifiers: { fullscreen: true, lock: true },
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDeptSave },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }