var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dragWrapper",
      staticClass: "org-tree-drag-wrapper",
      on: {
        mousedown: _vm.mousedownView,
        contextmenu: _vm.handleDocumentContextmenu,
      },
    },
    [
      _c(
        "div",
        { staticClass: "org-tree-wrapper", style: _vm.orgTreeStyle },
        [
          _vm.data
            ? _c("v-org-tree", {
                attrs: {
                  data: _vm.data,
                  "node-render": _vm.nodeRender,
                  "expand-all": true,
                  collapsable: "",
                },
                on: { "on-node-click": _vm.handleNodeClick },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }