import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 查询部门中对应的人员个数
 * @param params
 */
export function empCount() {
  return request({
    url: envInfo.bgApp.organizationPath + '/employee/empCount',
    method: 'get',
  })
}

/**
 * 查询人员列表
 * @param params
 */
export function getEmpPage(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/employee/empPage',
    method: 'get',
    params
  })
}

/**
 * 创建公司
 * @param params
 */
export function createCompany(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/company/create',
    method: 'post',
    data: params
  })
}

/**
 * 修改公司
 * @param params
 */
export function updateCompany(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/company/update',
    method: 'post',
    data: params
  })
}

/**
 * 根据ID查公司
 * @param params
 */
export function findCompanyById(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/company/getId?companyId='+params.companyId,
    method: 'get',
  })
}

/**
 * 删除公司
 * @param params
 */
export function deleteCompany(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/company/delete/' + params.companyId,
    method: 'delete',
  })
}

/**
 * 组织级联
 */
export function orgCascade() {
  return request({
    url: envInfo.bgApp.organizationPath + `/company/orgCascade`,
    method: 'get',
  })
}

/**
 * 创建部门
 * @param params
 */
export function createDept(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/dept/create',
    method: 'post',
    data: params
  })
}

/**
 * 修改部门
 * @param params
 */
export function updateDept(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/dept/update',
    method: 'post',
    data: params
  })
}

/**
 * 删除部门
 * @param params
 */
export function deleteDept(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/dept/delete/' + params.deptId,
    method: 'delete',
  })
}

/**
 * 查询组织结构列表
 * @param params
 */
export function getOrganizationTree(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/dept/list/tree',
    method: 'get',
    params
  })
}

/**
 * 查询部门中的岗位
 * @param params
 */
export function findDeptById(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/dept/getId',
    method: 'get',
    params
  })
}

/**
 * 下载人员模版
 */
export function downEmployeeTemplate() {
  downTemplate("ruge.employee", envInfo.bgApp.organizationPath);
}

/**
 * 删除人员
 * @param params
 */
export function deleteEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/employee/delete/${params.userId}`,
    method: 'delete'
  })
}

/**
 * 批量删除人员
 * @param params
 */
export function batchDeleteEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/employee/deleteList`,
    method: 'delete',
    params
  })
}

/**
 * 查询公司标签
 * @param params
 */
export function findCompanyLabel(params) {
  return request({
    url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  })
}
