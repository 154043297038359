<template>
    <div>
        <el-row>
            <el-col :span="12" style="line-height: 34px;">
                <title-icon icon-class="renyuan"/>{{employeeLabel}}
            </el-col>
            <el-col :span="11" :offset="1">
                <div class="filter-container" align="right">
                    <el-input :placeholder="$t('commons.searchAccount')"
                              maxlength="50" :show-word-limit="true"
                              v-model="listQuery.searchPhrase" clearable
                              size="small" style="width: 200px;" class="filter-item"
                              @keyup.enter.native="handleEmployeeFilter"/>
                    <el-button size="small" class="filter-item" type="primary"
                               :loading="employee.listLoading" icon="el-icon-search" @click="handleEmployeeFilter">
                        {{$t('commons.search') }}
                    </el-button>
                    <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="exportEmp">
                        {{$t('commons.export')}}
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <!--人员列表-->
        <el-table
                v-loading="employee.listLoading"
                :data="employee.list"
                element-loading-text="Loading"
                border
                fit
                highlight-current-row
                stripe
                @selection-change="handleSelectionChange">
            <el-table-column type="index" align="center" :label="$t('commons.index')" width="60"></el-table-column>
            <el-table-column prop="userAccount" :label="$t('base.employee.user')" width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.userAccount }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" :label="$t('base.employee.userName')" width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.userName }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="phone" :label="$t('base.employee.phone')">
                <template slot-scope="scope">
                    <span>{{ scope.row.phone }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="email" :label="$t('base.employee.email')">
                <template slot-scope="scope">
                    <span>{{ scope.row.email }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="dutyName" :label="$t('base.dept.dutyName')">
                <template slot-scope="scope">
                    <span>{{ scope.row.dutyName }}</span>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="employee.total > 0" :total="employee.total" :page.sync="listQuery.current"
                    :limit.sync="listQuery.rowCount"
                    @pagination="getEmployeesPage"/>
        <edit-employee :panelShow.sync="panelShow" :employeeForm="templateEmployee" :isCreate="isCreate"
                       :getEmployeesPage="getEmployeesPage"/>

        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon/>{{ $t('commons.uploadFile') }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess"
                        @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getEmpPage,
        downEmployeeTemplate,
        //deleteEmployee,
        //batchDeleteEmployee,
    } from '@/api/ruge/organization'
    import {
        exportEmployee,
    } from '@/api/ruge/employee'
    import store from '@/store/index'
    import Upload from '@/components/Upload'
    import Pagination from '@/components/Pagination'
    import {EditEmployee} from '@/views/ruge/employee/components'
    import {envInfo} from '@/constants/envInfo'

    export default {
        name: 'employeeDetails',
        props: {
            currentNode: {
                type: Object,
            },
            listQuery: {
                type: Object,
            },
        },
        components: {
            Pagination,
            EditEmployee,
            Upload
        },
        data() {
            return {
                rootPId: -999,
                employeeLabel: this.$t('base.company.companyEmployee'),
                //是否新增人员
                isCreate: null,
                panelShow: false,
                uploadModel: false,
                // Tooltip显示延迟时间(毫秒)
                openDelay: 1000,
                uploadType: "ExcelImport",
                excelType: "ruge.employee",
                rootPath: envInfo.bgApp.organizationPath,
                templateEmployee: null,
                employee: {
                    total: 0,
                    list: null,
                    listLoading: false,
                    form: {
                        userId: null,
                        empId: null,
                        userName: null,
                        userAccount: null,
                        deptId: null,
                        deptName: null,
                        dutyId: null,
                        dutyName: null,
                        companyId: null,
                        companyName: null,
                        phone: null,
                        email: null,
                        organiza: null,
                    },
                    multipleSelection: []
                },
            }
        },
        computed: {
            currentNodeChange() {
                return this.currentNode;
            },
        },
        watch: {
            currentNodeChange: function () {
                this.nodeChange();
            },
        },
        created() {
            this.nodeChange();
        },
        methods: {
            nodeChange() {
                let node = this.currentNode;
                if (node != null) {
                    if (node.parentId == this.rootPId) {
                        this.employeeLabel = this.$t('base.company.companyEmployee');
                    } else {
                        this.employeeLabel = this.$t('base.dept.deptEmployee');
                    }
                    this.getEmployeesPage();
                }
            },
            downEmpTemplate() {
                downEmployeeTemplate();
            },
            uploadSuccess() {
                this.uploadModel = false
                this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
            },
            uploadFail() {
                this.$message({type: 'error', message: this.$t('message.uploadFial')});
            },
            getEmployeesPage() {
                this.employee.listLoading = true;
                getEmpPage(this.listQuery).then(response => {
                    this.employee.total = response.total;
                    this.employee.list = response.rows;
                }).finally(() => {
                    this.employee.listLoading = false;
                });
            },
            handleEmployeeFilter() {
                this.listQuery.current = 1
                this.getEmployeesPage();
            },
            handleSelectionChange(data) {
                let userIds = [];
                data.forEach(function (obj) {
                    userIds.push(obj.userId);
                });
                this.employee.multipleSelection = userIds;
            },
            //修改人员
            updateEmployee(row) {
                this.templateEmployee = row;
                let arr = [];
                arr.push(row.companyId);
                if(row.deptId != null){
                    arr.push(row.deptId);
                }
                if(row.dutyId != null){
                    arr.push(row.dutyId);
                }
                this.templateEmployee.organiza = arr;
                this.templateEmployee.photo = envInfo.bgApp.ssoPath + "/param/avatar?u=" + row.userAccount;
                this.isCreate = false;
                this.panelShow = true;
            },
            createEmployee() {
                let node = this.currentNode;
                if (node == null) {
                    this.$message({
                        type: 'info',
                        message: this.$t('base.company.prompt')
                    });
                    return;
                }
                this.templateEmployee = {
                    userId: null,
                    empId: null,
                    userName: null,
                    userAccount: null,
                    deptId: null,
                    deptName: null,
                    dutyId: null,
                    dutyName: null,
                    companyId: null,
                    companyName: null,
                    phone: null,
                    email: null,
                    organiza: null,
                    photo: '',
                };
                let arr = [];
                arr.push(node.companyId);
                if (node.parentId != this.rootPId) {
                    arr.push(node.deptId);
                }
                this.templateEmployee.organiza = arr;
                this.isCreate = true;
                this.panelShow = true;
            },
            exportEmp() {
                exportEmployee(this.listQuery).then((msg) => {
                    this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                    let exportObj = {
                        taskId: msg,
                        taskName: "Employee",
                        taskStatus: 0,
                        rootPath: 'organizationPath'
                    };
                    //将导出任务丢入导出任务列表中
                    store.dispatch('PushExportTaskList', exportObj);
                }).finally(() => {
                    this.listLoading = false;
                })
            }
        },
    }
</script>

