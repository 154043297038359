<template>
  <div class="app-container-empty">
    <el-row>
      <el-col :style="{ width: 'calc(25% - 5px)' }">
        <el-row>
          <el-card class="orgCard">
            <div slot="header" class="cardHeader">
              <el-row>
                <el-col :span="16">
                  <tenant-select
                    @change="getOrganization"
                    :model="2"
                    v-model="listQuery.tenantId"
                  ></tenant-select>
                </el-col>
                <el-col
                  :span="8"
                  style="display: flex; flex-flow: row-reverse; margin-top: 4px"
                >
                  <template v-if="presentCompany != 0">
                    <el-radio-group
                      size="mini"
                      v-model="radioTab"
                      style="margin-left: 6px; display: flex"
                    >
                      <el-tooltip :content="$t('commons.overView')">
                        <el-radio-button label="1"
                          ><i class="el-icon-view"></i
                        ></el-radio-button>
                      </el-tooltip>
                      <el-tooltip :content="$t('commons.details')">
                        <el-radio-button label="2"
                          ><i class="el-icon-s-operation"></i
                        ></el-radio-button>
                      </el-tooltip>
                    </el-radio-group>
                  </template>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-tree
                v-loading="listLoading"
                :data="data"
                node-key="id"
                :auto-expand-parent="true"
                :highlight-current="true"
                :allow-drop="allowDrop"
                ref="tree"
              >
                <span
                  class="custom-tree-node"
                  slot-scope="{ node, data }"
                  @mouseenter="mouseoverNode(node)"
                  @mouseleave="mouseoutNode(node)"
                >
                  <span @click="clickNode(node, data)">
                    {{ node.label }}
                    <template v-if="data.parentId != rootPId">
                      ({{ data.empCount }})
                    </template>
                  </span>
                  <!--
                                <template v-if="node.data.show">
                                    <span>
                                      <el-button
                                              type="text"
                                              @click="() => removeNode(node, data)">
                                        <i class="el-icon-delete"></i>
                                      </el-button>
                                    </span>
                                </template>
                                -->
                </span>
              </el-tree>
            </div>
          </el-card>
        </el-row>
      </el-col>
      <el-col :style="{ width: 'calc(75% - 5px)', marginLeft: '10px' }">
        <template v-if="radioTab == 1">
          <el-card
            style="
              height: 100%;
              width: calc(75% - 5px);
              overflow-x: scroll;
              position: absolute;
            "
          >
            <div class="department-outer">
              <div class="tip-box">
                <title-icon /><b style="margin-right: 20px">{{
                  organizationName
                }}</b>
              </div>
              <div class="zoom-box">
                <zoom-controller
                  v-model="zoom"
                  :min="20"
                  :max="200"
                ></zoom-controller>
              </div>
              <div class="view-box">
                <org-view
                  v-if="companyData"
                  :data="companyData"
                  :zoom-handled="zoomHandled"
                  @on-menu-click="handleMenuClick"
                ></org-view>
              </div>
            </div>
          </el-card>
        </template>
        <template v-else>
          <el-row>
            <el-card class="orgCard">
              <div slot="header" class="cardHeader">
                <title-icon /><span>{{ publicTButton.label }}</span>
                <el-tag class="publicTag">{{ publicTButton.title }}</el-tag>
                <!--
                                <el-button type="primary" icon="el-icon-edit" class="publicButton" size="mini" @click="editOrganization">{{publicTButton.title}}</el-button>
                                <el-button type="success" icon="el-icon-plus" class="publicButton" size="mini" @click="createDeptModal">{{publicTButton.create}}</el-button>
                                -->
              </div>
              <div>
                <employee-details
                  :listQuery="employee.listQuery"
                  :currentNode="currentNode"
                />
              </div>
            </el-card>
          </el-row>
        </template>
      </el-col>
    </el-row>

    <!-- 编辑公司对话框 -->
    <el-dialog
      :visible.sync="company.addDialogVisible"
      :before-close="cancelCompanySave"
      width="30%"
      :close-on-click-modal="false"
    >
      <template slot="title"> <title-icon />{{ company.title }} </template>
      <el-form
        label-position="top"
        :model="company.form"
        ref="companyForm"
        :rules="company.formRules"
      >
        <el-input
          type="hidden"
          class="hidden"
          v-model="company.form.companyId"
        ></el-input>
        <el-form-item
          :label="$t('base.company.companyName')"
          prop="companyName"
        >
          <el-input
            maxlength="25"
            :show-word-limit="true"
            v-model="company.form.companyName"
            @input="setCompanyCode()"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('base.company.companyCode')"
          prop="companyCode"
        >
          <el-input
            maxlength="16"
            :show-word-limit="true"
            v-model="company.form.companyCode"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <template v-if="company.directorVisible == true">
          <el-form-item
            :label="$t('base.device.principal')"
            prop="companyDirectorId"
          >
            <el-select
              v-model="company.form.companyDirectorId"
              class="regionSelect"
              filterable
              clearable
              :placeholder="$t('commons.selectPlease')"
            >
              <el-option
                v-for="item in companyEmp"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item :label="$t('base.company.label')" prop="tagList">
          <tag-bind :tagValueList="company.form.tagList" tagType="company" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelCompanySave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleCompanySave"
          v-loading.fullscreen.lock="company.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑部门对话框 -->
    <el-dialog
      :visible.sync="dept.addDialogVisible"
      top="6vh"
      :before-close="cancelDeptSave"
      width="32%"
      :close-on-click-modal="false"
    >
      <template slot="title"> <title-icon />{{ dept.title }} </template>
      <el-form
        label-position="top"
        :model="dept.form"
        ref="deptForm"
        :rules="dept.formRules"
      >
        <el-input
          class="hidden"
          type="hidden"
          v-model="dept.form.deptId"
        ></el-input>
        <el-form-item :label="$t('base.dept.deptName')" prop="deptName">
          <el-input
            maxlength="33"
            :show-word-limit="true"
            v-model="dept.form.deptName"
            @input="setDeptCode()"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('base.dept.deptCode')" prop="deptCode">
          <el-input
            maxlength="16"
            :show-word-limit="true"
            v-model="dept.form.deptCode"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('base.company.name')" prop="companyId">
          <el-input
            type="hidden"
            class="hidden"
            v-model="dept.form.companyId"
          ></el-input>
          <el-input
            v-model="dept.form.companyName"
            readonly="readonly"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('base.dept.supDept')" prop="parentId">
          <el-input
            type="hidden"
            class="hidden"
            v-model="dept.form.parentId"
          ></el-input>
          <el-input
            v-model="dept.form.parentName"
            readonly="readonly"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('base.device.principal')" prop="deptHeadId">
          <el-select
            v-model="dept.form.deptHeadId"
            class="regionSelect"
            filterable
            clearable
            :placeholder="$t('commons.selectPlease')"
          >
            <el-option
              v-for="item in companyEmp"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="dept.deptDisable == true">
          <el-form-item :label="$t('base.dept.dutyName')" prop="dutyList">
            <template v-for="(dutyVO, index) in dept.form.dutyList">
              <div
                @mouseenter="dutyVO.show = true"
                @mouseleave="dutyVO.show = false"
                :key="dutyVO.dutyId"
                style="margin-bottom: 5px"
              >
                <el-input
                  type="hidden"
                  class="hidden"
                  v-model="dutyVO.dutyId"
                ></el-input>
                <el-input
                  class="dutyClass"
                  maxlength="33"
                  :show-word-limit="true"
                  v-model="dutyVO.dutyName"
                  style="margin-right: 5px"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
                <el-select
                  v-model="dutyVO.dutyPerson"
                  clearable
                  @clear="clearEmpty(dutyVO)"
                  :placeholder="$t('commons.selectPlease')"
                  style="margin-right: 5px"
                >
                  <el-option
                    v-for="item in deptEmp"
                    :key="item.userId"
                    :label="item.userName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
                <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  size="mini"
                  @click="addDutyNode"
                  round
                ></el-button>
                <el-button
                  type="warning"
                  icon="el-icon-delete"
                  size="mini"
                  @click="removeDutyNode(index)"
                  round
                ></el-button>
              </div>
            </template>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeptSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleDeptSave"
          v-loading.fullscreen.lock="dept.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrganizationTree,
  empCount,
  createCompany,
  updateCompany,
  createDept,
  updateDept,
  deleteCompany,
  deleteDept,
  findDeptById,
  findCompanyById,
} from "@/api/ruge/organization";
import { findEmployee } from "@/api/ruge/employee";
import { listToTree } from "@/utils/tree";
import TagBind from "@/components/TagBind";
import { EmployeeDetails } from "@/views/ruge/organization/components";
import OrgView from "@/views/ruge/organization/components/org-view.vue";
import ZoomController from "@/views/ruge/organization/components/zoom-controller.vue";
import "@/views/ruge/organization/index.less";
import TenantSelect from "@/components/TenantSelect";
import pinyin from "js-pinyin";

export default {
  name: "OrganizationManager",
  components: {
    TagBind,
    EmployeeDetails,
    OrgView,
    ZoomController,
    TenantSelect,
  },
  data() {
    return {
      // drawer: false,
      // direction: 'btt',
      radioTab: 1,
      zoom: 100,
      currentNode: null,
      rootPId: -999,
      //组织结构列表
      data: [],
      companyData: null,
      organizationName: this.$t("base.company.prompt"),
      //当前选中所属公司Id
      presentCompany: 0,
      //区域标签
      regionOptions: [],
      //公共标签 按钮
      publicTButton: {
        label: this.$t("base.company.companyName"),
        title: this.$t("base.company.name"),
        create: this.$t("base.dept.create"),
      },
      listLoading: false,
      listQuery: {
        tenantId: null,
      },
      company: {
        formRules: {
          companyName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          companyCode: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
        directorVisible: false,
        title: null,
        form: {
          companyId: null,
          companyName: null,
          companyCode: null,
          companyDirectorId: null,
          tagList: [],
        },
        addDialogVisible: false,
        createLoading: false,
      },
      dept: {
        formRules: {
          deptName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          deptCode: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
        deptDisable: null,
        title: null,
        form: {
          deptId: null,
          deptName: null,
          deptCode: null,
          companyId: null,
          companyName: null,
          parentId: null,
          parentCode: null,
          parentName: null,
          deptHeadId: null,
          dutyList: [
            {
              dutyId: 0,
              dutyName: null,
              deptId: null,
              dutyPerson: null,
              dutyPersonName: null,
              show: false,
            },
          ],
        },
        addDialogVisible: false,
        createLoading: false,
      },
      employee: {
        listQuery: {
          current: 1,
          rowCount: 10,
          searchPhrase: undefined,
          companyId: null,
          deptId: null,
        },
      },
      //记录部门人员个数 Map
      deptEmpMap: null,
      //公司中的人员
      companyEmp: [],
      deptEmp: [],
      //下级部门
      // subordinateDept : null,
    };
  },
  created() {
    this.getOrganization();
  },
  computed: {
    zoomHandled() {
      return this.zoom / 100;
    },
  },
  methods: {
    setCompanyCode() {
      let name = this.company.form.companyName;
      this.company.form.companyCode = pinyin.getCamelChars(name);
    },
    setDeptCode() {
      let name = this.dept.form.deptName;
      this.dept.form.deptCode = pinyin.getCamelChars(name);
    },
    handleMenuClick({ data, key }) {
      this.$refs.tree.setCurrentKey(data.id);
      this.publicTButton.label = data.label;
      let node = this.$refs.tree.getNode(data.id);
      this.currentNode = node.data;
      if (key == "edit") {
        this.editOrganization();
      }
      if (key == "create") {
        this.createDeptModal();
      }
      if (key == "delete") {
        this.removeNode(node, data);
      }
      if (key == "detail") {
        if (data.parentId == this.rootPId) {
          this.employee.listQuery.companyId = data.companyId;
          this.employee.listQuery.deptId = null;
          this.publicTButton.title = this.$t("base.company.name");
          this.publicTButton.create = this.$t("base.dept.create");
        } else {
          this.employee.listQuery.companyId = null;
          this.employee.listQuery.deptId = data.deptId;
          this.publicTButton.title = this.$t("base.dept.name");
          this.publicTButton.create = this.$t("base.dept.createSon");
        }
        this.radioTab = 2;
      }
    },
    getOrganizationTree() {
      this.listLoading = true;
      return getOrganizationTree(this.listQuery).then((response) => {
        let data = listToTree(response, "deptId", "parentId");
        console.log("xxx1", data);
        this.recursiveAttr(data);
        this.listLoading = false;
      });
    },
    getOrganization() {
      empCount().then((data) => {
        let deptEmpMap = new Map();
        data.forEach(function (obj) {
          deptEmpMap.set(obj.deptId, obj.empCount);
        });
        this.deptEmpMap = deptEmpMap;
        this.getOrganizationTree().then(() => {
          this.presentCompany = 0;
          this.organizationName = this.$t("base.company.prompt");
          this.publicTButton.label = this.$t("base.company.companyName");
          this.publicTButton.title = this.$t("base.company.name");
          this.publicTButton.create = this.$t("base.dept.create");
          this.companyData = null;
          this.radioTab = 1;
        });
      });
    },
    //递归修改属性及数据结构
    recursiveAttr(row) {
      let data = row.map((v) => {
        v.label = v.name;
        v.show = false;
        v.id = v.deptId;
        if (this.deptEmpMap.get(v.deptId) != undefined) {
          v.empCount = this.deptEmpMap.get(v.deptId);
        }
        if (v.children != undefined) {
          this.recursiveAttr(v.children);
        }
        return v;
      });
      this.data = data;
    },
    clickNode(node, data) {
      this.currentNode = data;
      this.presentCompany = data.companyId;
      this.publicTButton.label = data.label;
      if (node.level == 1) {
        this.companyData = node.data;
        this.organizationName = node.data.companyName;
        this.employee.listQuery.deptId = null;
        this.publicTButton.title = this.$t("base.company.name");
        this.publicTButton.create = this.$t("base.dept.create");
      } else {
        let rootNode = this.getCurrentRootNode(node);
        this.companyData = rootNode.data;
        this.organizationName = rootNode.data.companyName;
        this.employee.listQuery.deptId = data.deptId;
        this.publicTButton.title = this.$t("base.dept.name");
        this.publicTButton.create = this.$t("base.dept.createSon");
      }
      this.employee.listQuery.companyId = data.companyId;
      this.employee.listQuery.tenantId = this.listQuery.tenantId;
      // this.subordinateDept = data.children;
      //this.getEmployeesPage();
      this.searchCompanyEmp(data.companyId);
    },
    //获取当前节点的根节点(treeNode为当前节点)
    getCurrentRootNode(treeNode) {
      if (treeNode.data.parentId == this.rootPId) {
        return treeNode;
      } else {
        return this.getCurrentRootNode(treeNode.parent);
      }
    },
    // deptNode(dept){
    //     this.presentCompany = dept.companyId;
    //     this.publicTButton.label = dept.deptName;
    //     this.publicTButton.title = this.$t('base.dept.name');
    //     this.publicTButton.create = this.$t('base.dept.createSon');
    //     this.$refs.tree.setCurrentKey(dept.deptId);
    //     this.currentNode = this.$refs.tree.getCurrentNode();
    //     this.subordinateDept = dept.children;
    //
    //     this.employee.listQuery.companyId = dept.companyId;
    //     this.employee.listQuery.deptId = dept.deptId;
    //     //this.getEmployeesPage();
    //     this.searchCompanyEmp(dept.companyId);
    // },
    // 取消保存
    cancelCompanySave() {
      this.company.addDialogVisible = false;
    },
    handleCompanySave() {
      this.$refs.companyForm.validate((v) => {
        if (v) {
          //去重复
          this.company.form.tagList = Array.from(
            new Set(this.company.form.tagList)
          );
          if (this.company.form.companyId == null) {
            createCompany(this.company.form)
              .then((res) => {
                this.company.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //新建公司节点
                let node = this.$refs.tree.root;
                res.label = res.companyName;
                res.id = parseInt(res.companyId) * -2;
                res.parentId = -999;
                res.show = false;
                this.$refs.tree.append(res, node);
                //设置为选中状态
                this.$refs.tree.setCurrentKey(res.id);
                this.presentCompany = res.companyId;
                this.publicTButton.label = res.label;
                this.organizationName = res.companyName;
                this.employee.listQuery.deptId = null;
                this.employee.listQuery.companyId = res.companyId;
                this.publicTButton.title = this.$t("base.company.edit");
                this.publicTButton.create = this.$t("base.dept.create");
                let currentNode = this.$refs.tree.getNode(res.id);
                this.currentNode = currentNode.data;
                this.companyData = Object.assign({}, currentNode.data);
                //this.getOrganizationTree();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            if (this.company.form.companyDirectorId == "") {
              this.company.form.companyDirectorId = null;
            }
            updateCompany(this.company.form)
              .then(() => {
                this.company.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //更新公司节点
                this.currentNode.label = this.company.form.companyName;
                this.currentNode.companyName = this.company.form.companyName;
                this.currentNode.name = this.company.form.companyName;
                this.companyData = Object.assign({}, this.currentNode);
                this.publicTButton.label = this.company.form.companyName;
                this.publicTButton.title = this.$t("base.company.edit");
                this.publicTButton.create = this.$t("base.dept.create");
                //this.getOrganizationTree();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    cancelDeptSave() {
      this.$refs.deptForm.resetFields();
      this.dept.addDialogVisible = false;
    },
    handleDeptSave() {
      this.$refs.deptForm.validate((v) => {
        if (v) {
          if (this.dept.form.deptId == null) {
            createDept(this.dept.form)
              .then((res) => {
                this.dept.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //新建部门节点
                let key = this.$refs.tree.getCurrentKey();
                let node = this.$refs.tree.getNode(key);
                res.label = res.deptName;
                res.id = res.deptId;
                res.show = false;
                this.$refs.tree.append(res, node);
                let rootNode = this.getCurrentRootNode(node);
                this.companyData = Object.assign({}, rootNode.data);
                //this.getOrganizationTree();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            if (this.dept.form.deptHeadId == "") {
              this.dept.form.deptHeadId = null;
            }
            let ele = this;
            this.dept.form.dutyList.forEach(function (obj, index) {
              if (
                obj.dutyId == 0 &&
                (obj.dutyName == null || obj.dutyName.trim() == "")
              ) {
                ele.dept.form.dutyList.splice(index, 1);
              }
            });
            updateDept(this.dept.form)
              .then(() => {
                this.dept.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //更新部门节点
                this.currentNode.label = this.dept.form.deptName;
                this.currentNode.deptName = this.dept.form.deptName;
                this.currentNode.name = this.dept.form.deptName;
                let key = this.$refs.tree.getCurrentKey();
                let rootNode = this.getCurrentRootNode(
                  this.$refs.tree.getNode(key)
                );
                this.companyData = Object.assign({}, rootNode.data);
                this.publicTButton.label = this.dept.form.deptName;
                this.publicTButton.title = this.$t("base.dept.edit");
                this.publicTButton.create = this.$t("base.dept.createSon");
                //this.getOrganizationTree();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    editOrganization() {
      if (this.presentCompany == 0) {
        this.$message(this.$t("base.company.prompt"));
      } else {
        let node = this.$refs.tree.getCurrentNode();
        if (node.parentId == this.rootPId) {
          this.company.directorVisible = true;
          this.company.title = this.$t("base.company.edit");
          this.company.addDialogVisible = true;

          findCompanyById({ companyId: node.companyId }).then((companyVO) => {
            this.company.form = companyVO;
          });
        } else {
          findDeptById({ deptId: node.deptId })
            .then((resdata) => {
              if (resdata.dutyList.length == 0 || resdata.dutyList == null) {
                resdata.dutyList = [
                  {
                    dutyId: 0,
                    dutyName: null,
                    deptId: node.deptId,
                    dutyPerson: null,
                    dutyPersonName: null,
                    show: false,
                  },
                ];
              } else {
                resdata.dutyList.forEach(function (obj) {
                  obj.show = false;
                });
              }
              this.dept.form = resdata;
              this.searchDeptEmp(resdata.deptId);
              this.dept.title = this.$t("base.dept.edit");
              if (resdata.parentId < 0) {
                this.dept.form.parentId = -1;
                this.dept.form.parentName = this.$t("base.dept.notSupDept");
              }
              this.dept.deptDisable = true;
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            });
          this.dept.addDialogVisible = true;
        }
      }
    },
    allowDrop() {
      return true;
    },
    mouseoverNode(node) {
      node.data.show = true;
    },
    mouseoutNode(node) {
      node.data.show = false;
    },
    removeNode(node, data) {
      if (node.isLeaf) {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            if (node.level == 1) {
              deleteCompany({ companyId: data.companyId }).then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
                //删除公司节点
                this.$refs.tree.remove(node);
                this.presentCompany = 0;
                this.organizationName = this.$t("base.company.prompt");
                this.publicTButton.label = this.$t("base.company.companyName");
                this.publicTButton.title = this.$t("base.company.edit");
                this.publicTButton.create = this.$t("base.dept.create");
                this.companyData = null;
                this.radioTab = 1;
              });
            } else {
              deleteDept({ deptId: data.deptId }).then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
                //删除部门节点
                let parentNode = node.parent;
                this.$refs.tree.remove(node);
                let rootNode = this.getCurrentRootNode(parentNode);
                this.companyData = Object.assign({}, rootNode.data);
                this.$refs.tree.setCurrentKey(rootNode.data.id);

                this.presentCompany = rootNode.data.companyId;
                this.publicTButton.label = rootNode.data.companyName;
                this.organizationName = rootNode.data.companyName;
                this.employee.listQuery.deptId = null;
                this.publicTButton.title = this.$t("base.company.edit");
                this.publicTButton.create = this.$t("base.dept.create");
                this.currentNode = rootNode.data;
                this.companyData = Object.assign({}, rootNode.data);
              });
            }
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      } else {
        // 失败提示
        this.$message({
          type: "info",
          message: this.$t("commons.notDeleteParent"),
        });
      }
    },
    addDutyNode() {
      this.dept.form.dutyList.push({
        dutyId: 0,
        dutyName: null,
        deptId: this.dept.form.deptId,
        dutyPerson: null,
        dutyPersonName: null,
        show: false,
      });
    },
    removeDutyNode(index) {
      this.dept.form.dutyList.splice(index, 1);
    },
    // createCompanyModal(){
    //     this.company.title=this.$t('base.company.create')
    //     this.company.directorVisible = false;
    //     this.clearCompanyForm();
    //     this.company.addDialogVisible = true;
    // },
    createDeptModal() {
      this.dept.title = this.$t("base.dept.create");
      this.clearDeptForm();
      this.dept.deptDisable = false;
      let node = this.$refs.tree.getCurrentNode();
      if (node != null) {
        if (node.parentId == this.rootPId) {
          this.dept.form.parentId = -1;
          this.dept.form.parentName = this.$t("base.dept.notSupDept");
        } else {
          this.dept.form.parentId = node.deptId;
          this.dept.form.parentName = node.deptName;
        }
        this.dept.form.companyId = node.companyId;
        this.dept.form.companyName = node.companyName;
        this.dept.addDialogVisible = true;
      } else {
        this.$message(this.$t("base.company.prompt"));
      }
    },
    clearEmpty(dutyVO) {
      dutyVO.dutyPerson = null;
    },
    clearCompanyForm() {
      this.company.form.companyId = null;
      this.company.form.companyCode = null;
      this.company.form.companyDirectorId = null;
      this.company.form.companyName = null;
      this.company.form.tagList = [];
    },
    clearDeptForm() {
      this.dept.form.deptId = null;
      this.dept.form.deptName = null;
      this.dept.form.deptCode = null;
      this.dept.form.companyId = null;
      this.dept.form.companyName = null;
      this.dept.form.parentId = null;
      this.dept.form.parentName = null;
      this.dept.form.deptHeadId = null;
    },
    //获取公司下的人员
    searchCompanyEmp(companyId) {
      findEmployee({
        companyId: companyId,
        tenantId: this.listQuery.tenantId,
      }).then((response) => {
        this.companyEmp = response;
      });
    },
    //获取部门下的人员
    searchDeptEmp(deptId) {
      findEmployee({ deptId: deptId, tenantId: this.listQuery.tenantId }).then(
        (response) => {
          this.deptEmp = response;
        }
      );
    },
  },
};
</script>

<style scoped>
.orgCard >>> .el-card__header {
  padding: 6px 10px;
}
.orgCard >>> .el-card__body {
  height: calc(100vh - 140px);
  overflow: scroll;
}
.cardHeader {
  line-height: 34px;
  font-weight: 600;
}
.publicButton {
  margin-left: 10px;
}
.regionSelect {
  width: 100%;
}
.dutyClass {
  width: 35%;
}
.publicTag {
  margin-left: 6px;
}
</style>
