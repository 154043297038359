<template>
    <div class="zoom-wrapper">
        <el-button icon="el-icon-zoom-out" circle size="mini" @click="scale('down')"></el-button>
        <span class="zoom-number">{{ value }}%</span>
        <el-button icon="el-icon-zoom-in" circle size="mini" @click="scale('up')"></el-button>
    </div>
</template>

<script>
    export default {
        name: 'ZoomController',
        props: {
            value: {
                type: Number,
                default: 100
            },
            step: {
                type: Number,
                default: 20
            },
            min: {
                type: Number,
                default: 10
            },
            max: {
                type: Number,
                default: 200
            }
        },
        methods: {
            scale(type) {
                const zoom = this.value + (type === 'down' ? -this.step : this.step)
                if (
                    (zoom < this.min && type === 'down') ||
                    (zoom > this.max && type === 'up')
                ) {
                    return
                }
                this.$emit('input', zoom)
            }
        }
    }
</script>

<style lang="less">
    .trans(@duration) {
        transition: ~"all @{duration} ease-in";
    }

    .zoom-wrapper {
        .zoom-number {
            color: #657180;
            padding: 0 8px;
            display: inline-block;
            width: 56px;
            text-align: center;
        }
    }
</style>
